.body-container {
	position: relative;
}

main {
	background-color: white;
	color: $color_p;
}

.container {
	max-width: none !important;
	width: 100%;
	min-width: 900px;
}

h1 {
	color: $secondary;
}

h2, h3, h4, h5 {
	color: $secondary;
}

h1 {font-size: 38px;}
h2 {font-size: 25px; margin-bottom: 25px;}

.btn {
	border-radius: 40px;
}

.bg-grey {
	background-color: $grey-light;
}

p {
	color: $color_p;

	&.desc {
		line-height: 2.4;
	}
}

.white {color: white;}
.red {color: red;}

.hidden {
	display:none;
}

.relative {
	position: relative;
}

.slash {
	position: absolute;
	left: -2px;
  top: 7px;
}

.collapse-wrapper {
	width: 80%;
	position: relative;
	margin: auto;

	.collapse__line {
		border-bottom: 1px solid $border-color;
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: -13px;
		left: 0;
	}

	.collapse__icon {
		background-color: white;
		position: relative;
	}
}

.form-control--w70 {
	width: 70px !important;
}

pre.email-header {
	font-size: 100%;
}

.email-content {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	overflow-y: auto !important;
}

pre.email-preview {
	overflow: hidden;
}

.pt-30vh {
	padding-top: 30vh;
}

.inline {
	display: inline-block;
}

.text-normal {
	font-size: 15px;
}

.pre-line {
	white-space: pre-line;
}
