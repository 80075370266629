.form-control::placeholder,
.css-1wa3eu0-placeholder {
	// color: $grey-light;
	opacity: 0.7;
}

.form-control.disabled {
	background-color: transparent;
	border: none;
	padding: 0;
	height: auto;
}

select.disabled {
	appearance:none;
}

.form-check {
	display: inline-block;
	margin-right: 20px;
	margin-top: 7px;
}

.form-control-inline {
	display: inline-block;
	width: auto;
	margin-right: 10px;
}

.form-control--short {
	width: 45px;
}

.form-control--medium {
	width: 70px;
}

input[type=checkbox] {
	height: 18px;
	width: 18px;
	margin-right: 5px;
}

label.disabled,
p.disabled,
.disabled {
	color: #b3b3b3;
}

input.form-control.disabled {
	background-color: transparent;
	height: auto;
	border: 1px solid $border-color;
	background-color: $grey-light;
	padding: .375rem .75rem;
}

.form-check {
	margin-top: 0.3rem;
}

.form-control {
	border-radius: 0;
}

.form-control.red {
	border-radius: 0;

	&:focus {
		color: red;
	}
}

label {
	// margin-top: 0.3rem;

	&[type="checkbox"] {
		margin-top: 0;
	}
}

.custom-control-label {
	vertical-align: unset;
}
