.home-page {
  .jumbotron {
    background-image: url('/images/jumbotron.jpg');
    background-size: cover;
    background-position: center;

    .jumbotron__text {
      background-color: #2f2f31;
      padding-left: 5px;
      padding-right: 5px;
      color: white;
    }
  }
}
