.react-bootstrap-daterangepicker-container {
  display: block !important;
}

.css-yk16xz-control {
  border-color: #ced4da !important;
}

.date-range__cancel-icon {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.23;
}
