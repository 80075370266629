.btn {
	border-radius: 0;
}

.btn-primary {
	background-color: $primary;
	border-color: $primary;

	&:hover, &:active {
		background-color: $primary-hover !important;
		border-color: $primary-hover !important;
	}
}

a {
	color: $primary;

	&:hover, &:active {
		color: $primary-hover;
	}
}

.link {
	text-decoration: underline;
}

.btn-link {
	color: $secondary;
	text-decoration: underline;
}

.btn--lg {
	font-size: 14px;
	height: 55px;
	min-width: 180px;
}

.btn-outline-light {
	&:hover, &:active {
		color: $primary;
	}
}

.loading-btn {
	position: relative;
	background-color: $secondary;
	color: #afafaf;

	span {
		position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
		color: white;
	}
}

.back-btn-wrapper {
	position: absolute;
	top: 5px;
	z-index: 2;
}

.btn-none {
	background-color: transparent;
}

.btn-icon-round {
	padding: 0;
	border: 1px solid;
	height: 22px;
	width: 22px;
	border-radius: 50%;
	line-height: 20px;
}

.btn-primary.disabled, .btn-primary:disabled {
	background-color: $primary;
	border-color: $primary;
}
