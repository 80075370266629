.alertMsg__content__td {
  position: relative;
  overflow: auto;
}

.alertMsg__content {
  white-space: pre;
  max-height: 95px;
  max-width: 408px;
  margin-bottom: 0;
}
