.tabs{
	margin: 0px;
	padding: 0px;
	list-style: none;
	border-bottom: 1px solid $grey;

	.nav-item {
		padding: 0;

		&:hover {
			background-color: transparent;
		}
	}

	.nav-link {
		// border: 2px solid transparent;
		display: inline-block;
		padding: 10px 15px;
		cursor: pointer;
		border-radius: 0;

		&.active,
		&:hover {
			background: white;
		}
	}
}

.tab-action--wrapper {
	margin-left: auto;

	.dropdown-toggle {
		&:after {
	    margin-left: 0;
			content: none;
		}
	}

	.svg-inline--fa.fa-w-16,
	.svg-inline--fa.fa-w-14 {
		width: 18px;
	}

	.dropdown-item.disabled {
		color: #b1b4b7;
	}
}

.tab-content{
	padding: 15px;
	position: relative;
	background-color: white;
}

.tab-content.current{
	display: inherit;
}

.list-group-item {
	&.active {
		background-color: $info;
		outline: transparent;
    border-color: $info;
	}
	&.disabled {
		color: #a2a2a2;
	}
}
