.modal--roster {
  background-color: rgba(0, 0, 0, 0.5);
	&.fade.show-model {
		display: block !important;
		opacity: 1 !important;
	}
	.modal-dialog {
		z-index: 3;
    margin-top: 4rem;
	}
  .modal-body {
    height: calc(95vh - 69px - 71px);
    overflow-y: auto;
  }
}
