.search-select {
	position: relative;

	input {
		padding-right: 36px;

		&.isClearable {
			padding-right: 67px;
		}
	}

	&__option-box {
		max-height: 200px;
    overflow: auto;
    min-width: 100%;
		margin-top: 5px;
	}

	&__right-icon {
		position: absolute;
		color: #bbbbbb;
    right: 10px;
    top: 7px;
	}

	&__left-icon {
		position: absolute;
		color: grey;
    right: 35px;
    top: 7px;
	}

	button {
		color: #bbbbbb;
		vertical-align: unset;
    top: 5px;
	}

	&__no-result {
		color: grey;
	}

	&__option-box {
		box-shadow: 0px 3px 9px 0px #00000026
	}
}
