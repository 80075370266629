.left-nav {
	max-width: $left-nav-width;
	width: $left-nav-width;
	position: fixed;
	height: 100%;
	z-index: 10;
	border-right: 7px solid transparent;
	transition: margin 0.75s;
	background: $bg-color;
	background: linear-gradient(0deg, rgba(95,105,111,1) 0%, rgba(119,132,139,1) 100%);

	&.nav-open {
		margin-left: 0;
	}

	&.nav-close {
		margin-left: calc(7px - #{$left-nav-width});
	}

	&__user-email {
		word-break: break-word;
	}

	.list-group-item {
		padding: 0;
		border: none;
		background-color: transparent;

		.nav-link,
		button.nav-link {
			color: white;
			background-color: transparent;
		}

		&.active,
		&:hover {
			// background-color: white;
			border-color: transparent;

			.nav-link,
			button.nav-link {
				background-color: white !important;
				color: black !important;
			}
		}

		&.disabled {
			background-color: transparent;
			cursor: not-allowed;
			.nav-link {
				opacity: 0.4;
			}
		}
	}

	&__content {
		height: 100%;
	}

}

.nav-item {
	padding: 4px 0;
	transition: 0.1s;

	&:hover {
		background-color: #624764
	}
}

.toggle-nav-wrapper {
	position: absolute;
	right: -47px;
	top: 60px;
	background-color: #748188;
	color: white;
	border-radius: 0 5px 5px 0;
}

.bottom-nav {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.notification-dot {
	position: absolute;
	right: -4px;
	top: -4px;
}
