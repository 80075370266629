.additional-info {
  position: absolute;
right: -16px;
top: -3px;
}

.popover {
	max-width: 450px;
	width: 450px;
	position: absolute;
	left: -542px;
	top: -50px;
}

.btn-additional {
	padding: 0;
}
