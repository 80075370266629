.sticky-header-active {
  padding-top: 331px;
}

.service-detail__desc {
  line-height: 37px;
  margin-bottom: 0;
}

.tooltip {
  .tooltip-inner {
    background-color: $info;
  }
  &.bs-tooltip-top {
    .arrow:before {
      border-top-color: $info;
    }
  }
  &.bs-tooltip-right {
    .arrow:before {
      border-right-color: $info;
    }
  }
}

.read-blue {
  color: $read-blue;
}

.booking-number-dropdown {
  display: inline;

  .dropdown-toggle {
    font-weight: bold;
    font-size: 18px;
  }

  .dropdown-menu {
    width: 100%;
  }
}

.booking-number {
  display: inline;
  font-weight: bold;
  font-size: 18px;
}

.service-status-HK,
.service-status-HK:focus {
  background-color: $success;
  color: white;
}

.service-status-XX,
.service-status-XX:focus {
  background-color: $danger;
  color: white;
}

.service-status-RQ,
.service-status-RQ:focus {
  background-color: $warning;
}

.detail-label {
  text-align: right;
  border-right: 1px solid #59c0da;
  font-weight: bold;
  color: #55595d;
}

.sticky-inner-wrapper {
	z-index: 2;
	background-color: white;
}

.big-indicator {
    font-size: 446px;
    position: absolute;
    bottom: 0;
    opacity: 0.07;
    right: 180px;
		font-family: 'Helvetica';
    line-height: 308px;
}

.w-booking {
  background-color: $wBgColor;

	.sticky-inner-wrapper {
		background-color: $wBgColor;
	}

	.btn-light {
		background-color: white;
	}

	.big-indicator {
		color: $wColor;
	}

	.detail-label {
		border-color: $wColor;
	}

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: $wBgColor;
  }
}

.one-booking {
  background-color: $oneBgColor;

	.sticky-inner-wrapper {
		background-color: $oneBgColor;
	}

	.btn-light {
		background-color: white;
	}

	.big-indicator {
		color: $oneColor;
	}

	.detail-label {
		border-color: $oneColor;
	}

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: $oneBgColor;
  }

  .detail-badge-value {
    color:#212529;
  }

}

table .row {
  margin-left: -13px;
  margin-right: -13px;
}

.stickyactive {
  height: auto !important;
}
