.css-yk16xz-control,
.css-1pahdxg-control,
.css-1fhf3k1-control,
.css-1q13pxr,
.css-13uywwx {
	border-radius: 0 !important;
}

.invalid-select {
	.css-yk16xz-control,
	.css-1pahdxg-control,
	.css-1fhf3k1-control {
		border-color: $danger !important;
	}
}

.ComboBoxList {
	z-index: 1;

	li {
		padding: 0 !important;
	}
}

.product-search-bar__loading {
		position: absolute;
    right: 32px;
    top: 11px;
	}
