.status-breakdown {
  font-size: 15px;
  font-weight: bold;

  span::after {
    content: ', '
  }
  span:last-child::after {
    content: ''
  }
}
